import React, { useState } from 'react';
import { FaFacebook, FaTwitter, FaWhatsapp, FaShareAlt } from 'react-icons/fa';
import './ImageCard.css';
import image from '../assets/icimg1.jpg'; // Make sure the path is correct

const ImageCard = ({ heading, title, description }) => {
  const [isHovered, setIsHovered] = useState(false);

  // Handlers for hovering on share button or share options
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <div className="image-card">
      {/* Top Bar with Heading and Share Button */}
      <div className="top-bar">
        <h2 className="head">{heading}</h2>
        {/* Share Button with Hover Effect */}
        <button
          className="share-btn"
          onMouseEnter={handleMouseEnter} // Show the social links when hovered
          onMouseLeave={handleMouseLeave} // Hide the social links when mouse leaves
        >
          <FaShareAlt /> {/* Share icon */}
        </button>
        {/* Share Options (Dropdown Menu) */}
        <div
          className={`share-options ${isHovered ? 'show' : ''}`}
          onMouseEnter={handleMouseEnter} // Stay visible if hovering on the social links
          onMouseLeave={handleMouseLeave} // Hide the social links if mouse leaves
        >
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook className="social-icon" />
          </a>
          <a
            href={`https://twitter.com/intent/tweet?url=${window.location.href}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter className="social-icon" />
          </a>
          <a
            href={`https://wa.me/?text=${window.location.href}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaWhatsapp className="social-icon" />
          </a>
        </div>
      </div>

      {/* Image with Hover Effect */}
      <div className="image-container">
        <img src={image} alt={title} className="image" /> {/* Display image from assets */}
      </div>

      {/* Title and Description */}
      <div className="content">
        <h3 className="title">{title}</h3>
        <p className="description">{description}</p>
      </div>
    </div>
  );
};

export default ImageCard;
