import React from "react";
import { FaBars, FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaSearch } from "react-icons/fa"; // Import icons
import logo from "../assets/webl.png"; // Adjust the path based on your folder structure
import "./Navbar.css";

const Navbar = ({ toggleSidebar }) => {
  return (
    <nav className="navbar">
      {/* Left Section */}
      <div className="left">
        {/* Social Icons */}
        <ul className="social-icons">
          <li>
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
              <FaFacebookF />
            </a>
          </li>
          <li>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <FaTwitter />
            </a>
          </li>
          <li>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
          </li>
          <li>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
              <FaLinkedinIn />
            </a>
          </li>
        </ul>
      </div>

      {/* Middle Section */}
      <div className="middle">
        <div className="search-container">
          <input type="text" className="search-bar" placeholder="...Search" />
          <FaSearch className="search-icon" />
        </div>
      </div>

      {/* Right Section */}
      <div className="right">
        {/* Logo */}
        <img src={logo} alt="Logo" className="logonav" />
      </div>

      {/* Hamburger Menu Icon */}
      <div className="menu-icon" onClick={toggleSidebar}>
          <FaBars />
        </div>
    </nav>
  );
};

export default Navbar;
