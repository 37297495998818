import React, { useState, useEffect } from "react";
import "./HeroSlider.css";

const HeroSlider = () => {
  const images = [
    "https://via.placeholder.com/1200x500/FF5733/FFFFFF?text=Hero+Image+1",
    "https://via.placeholder.com/1200x500/33A1FF/FFFFFF?text=Hero+Image+2",
    "https://via.placeholder.com/1200x500/33FF57/FFFFFF?text=Hero+Image+3",
    // Add more images here
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  // Automatic slider change every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [images.length]);

  // Change slide on dot click
  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="hero-slider">
      {/* Slider Image */}
      <img
        src={images[currentIndex]}
        alt={`Slide ${currentIndex}`}
        className="slider-image"
      />

      {/* Dots for navigation */}
      <div className="dots">
        {images.map((_, index) => (
          <span
            key={index}
            className={`dot ${currentIndex === index ? "active" : ""}`}
            onClick={() => goToSlide(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default HeroSlider;
