import React from 'react';
import HeroSlider from './HeroSlider';
import './Content.css';
import logo from '../assets/webl.png'; // Adjust the path based on your folder structure
import ImageCard from './ImageCard';

const Content = () => {
  return (
    <div className="content-container">
      <div>
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <div id="acard">
        <p>This is a sample layout for announcement notice written here.</p>
        <h4>Announcements</h4>
      </div>
    <div>
      <HeroSlider/>
      </div>
      <div className="responsive-container">
      <p className="responsive-paragraph">  
نحن النجم للمظلات والسواتر ,تركيب مظلات وسواتر برجولات نتواجد في جميع مدن المملكة العربية السعودية موقعنا الرياض تركيب مظلات وسواتر .مظلات سيارات مظلات حدائق منزليه وعامه مظلات مواقف سيارات مظلات تغطيه خزانات مياه مظلات مسابح برجولات حدائق برجولات حديد برجولات خشب مظلات خشبية مظلات حديد مظلات خشب بلاستيك وايضا سواتر جدارية منها سواتر قماش وسواتر حديد وسواتر خشب معالج وقرميد وبيوت شعر وخيام ومقاول بناء هناجر ومستودعات شبوك مزارع الشغل الذمه والفن يخلف ذكرا طيبه
      </p>
      <img
        src="https://via.placeholder.com/800"
        alt="Big Display"
        className="responsive-image"
      />
    </div>

      <div>
        <div className='heading'>
        <img src='curvedarrow.png' alt='arrowpng' id='aic'/>
        <h2>Latest Work </h2>
        </div>
        <ImageCard/>
      </div>
      
    </div>
  );
};

export default Content;
