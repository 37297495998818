import React, { useState } from "react";
import Navbar from "./Components/Navbar";
import Sidebar from "./Components/SideBar";
import Content from "./Components/Content";
import "./App.css";

const App = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Toggle sidebar visibility
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="app">
      {/* Navbar */}
      <Navbar toggleSidebar={toggleSidebar} />

      {/* Main Layout */}
      <div className="main-layout">
        {/* Content */}
        <Content />
        {/* Sidebar */}
        <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      </div>
    </div>

    
  );
};

export default App;
