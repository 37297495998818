import React from "react";
import { FaTimes } from "react-icons/fa"; // For Close button
import "./SideBar.css"; // Sidebar styles

const Sidebar = ({ isOpen, toggleSidebar }) => {
  return (
    <div className={`sidebar ${isOpen ? "open" : ""}`}>
      {/* Close Button */}
      <div className="close-btn" onClick={toggleSidebar}>
        <FaTimes />
      </div>
      <ul>
        <li><a href="#home">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#services">Services</a></li>
        <li><a href="#contact">Contact</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#blog">Blog</a></li>
        <li><a href="#testimonials">Testimonials</a></li>
        <li><a href="#team">Team</a></li>
        <li><a href="#faq">FAQ</a></li>
        <li><a href="#pricing">Pricing</a></li>
        <li><a href="#careers">Careers</a></li>
        <li><a href="#partners">Partners</a></li>
        <li><a href="#resources">Resources</a></li>
        <li><a href="#news">News</a></li>
        <li><a href="#events">Events</a></li>
        <li><a href="#contact-us">Contact Us</a></li>
        <li><a href="#support">Support</a></li>
        <li><a href="#terms">Terms</a></li>
        <li><a href="#privacy">Privacy Policy</a></li>
        <li><a href="#terms-conditions">Terms & Conditions</a></li>
        <li><a href="#legal">Legal</a></li>
        <li><a href="#blog-posts">Blog Posts</a></li>
        <li><a href="#newsletter">Newsletter</a></li>
        <li><a href="#community">Community</a></li>
        <li><a href="#sitemap">Sitemap</a></li>
        <li><a href="#locations">Locations</a></li>
        <li><a href="#contact-form">Contact Form</a></li>
      </ul>
    </div>
  );
};

export default Sidebar;
